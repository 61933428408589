import { Suspense, lazy, useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "animate.css/animate.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import ProductoUnitario from "./components/Producto_unitario/ProductoUnitario";
import Header from "./components/Header/Header";
import { LanguageProvider } from "./context/LanguageContext";
import LanguageSelector from "./components/Floating/LanguageSelector";

const Error404 = lazy(() => import("./components/404/Error404"));
const Inicio = lazy(() => import("./screen/Inicio/Inicio"));
const QuienesSomos = lazy(() => import("./screen/Quienes_somos/QuienesSomos"));
const Agrinutrientes = lazy(() =>
  import("./components/ProductosComponentes/Agrinutrientes/Agrinutrientes")
);
const Catalogo = lazy(() => import("./screen/Catalogo/Catalogo"));
const Distribuidores = lazy(() =>
  import("./screen/Distribuidores/Distribuidores")
);
const Contacto = lazy(() => import("./screen/Contactato/Contacto"));
const Especialidades = lazy(() =>
  import("./components/ProductosComponentes/Especialidades/Especialidades")
);
const Bioestimulantes = lazy(() =>
  import("./components/ProductosComponentes/Bioestimulantes/Bioestimulantes")
);
const Bioprotectores = lazy(() =>
  import("./components/ProductosComponentes/Bioprotectores/Bioprotectores")
);
const CorrectoresAS = lazy(() =>
  import("./components/ProductosComponentes/CorrectoreAS/CorrectoresAS")
);
const Coadyuvantes = lazy(() =>
  import("./components/ProductosComponentes/Coadyuvantes/Coadyuvantes")
);
const Hidrosolubles = lazy(() =>
  import("./components/ProductosComponentes/Hidrosolubles/Hidrosolubles")
);

function App() {
  return (
    <LanguageProvider>
      <div className="app">
        <Router>
          <Routes>
            <Route path="/" element={<Layyout />}>
              <Route path="*" element={<Error404 />} />

              <Route path="/" element={<Inicio />} />
              <Route path="/quiene-somos" element={<QuienesSomos />} />
              <Route path="/catalogo" element={<Catalogo />} />
              <Route path="/distribuidores" element={<Distribuidores />} />
              <Route path="/contactanos" element={<Contacto />} />
              <Route path="/agrinutrientes" element={<Agrinutrientes />} />
              <Route path="/especialidades" element={<Especialidades />} />
              <Route path="/bioestimulantes" element={<Bioestimulantes />} />
              <Route path="/bioprotectores" element={<Bioprotectores />} />
              <Route path="/correctoresAS" element={<CorrectoresAS />} />
              <Route path="/coadyuvantes" element={<Coadyuvantes />} />
              <Route path="/hidrosolubles" element={<Hidrosolubles />} />
              <Route
                path="/producto/:nombre_producto"
                element={<ProductoUnitario />}
              />
            </Route>
          </Routes>
        </Router>

        <LanguageSelector />
      </div>
    </LanguageProvider>
  );
}
// hola
function Layyout() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Header isSticky={isSticky} />

      <Suspense fallback={<h1>Cargando...</h1>}>
        <Outlet />
      </Suspense>
    </>
  );
}

export default App;
