/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState } from "react";
import "./Header.css";
import logoPoten from "./../../assets/img/logopontez.png";
import logoPotenehite from "./../../assets/img/logopontez_white.png";

import agrinutriente_img from "./../../assets/img/Catalogo_img/AGRINUTRIENTES_new.png";
import especialidades_img from "./../../assets/img/Catalogo_img/ESPECIALIDADES_new.png";
import bioestimulante_img from "./../../assets/img/Catalogo_img/bioestimulantes_new.png";
import bioprotectores_img from "./../../assets/img/Catalogo_img/bioproterores_new.png";
import correctore_img from "./../../assets/img/Catalogo_img/correctore_de_agua.png";
import coadyuvantes from "./../../assets/img/Catalogo_img/COADYUVANTES_new.png";
import hidrosolubles from "./../../assets/img/Catalogo_img/Hidrosolubles_new.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Header({ isSticky }) {
  const { t } = useTranslation();
  const productos = [
    // AGRINUTRIENTES
    {
      nombre: t("Agrinutriente_Zn_b.nombre"),
      titulo: "Agrinutriente Zn+B",
      color: "#7abe00",
    },
    {
      nombre: t("Agrinutriente_Ca_b.nombre"),
      titulo: "Agrinutriente Ca+B",
      color: "#7abe00",
    },
    {
      nombre: t("Agrinutriente_17_17_17.nombre"),
      titulo: "Agrinutriente 17-17-17",
      color: "#7abe00",
    },
    {
      nombre: t("Agrinutriente_B_1.nombre"),
      titulo: "Agrinutriente B",
      color: "#7abe00",
    },
    // ESPECIALIDADES
    {
      nombre: t("Meister_Papa_1.nombre"),
      titulo: "Meister Papa",
      color: "#91016C",
    },
    {
      nombre: t("Meister_Maíz_1.nombre"),
      titulo: "Meister Maíz",
      color: "#91016C",
    },
    {
      nombre: t("Meister_Frijol_1.nombre"),
      titulo: "Meister Frijol",
      color: "#91016C",
    },
    {
      nombre: t("Meister_Café_1.nombre"),
      titulo: "Meister Café",
      color: "#91016C",
    },
    {
      nombre: t("Meister_Ajonjolí_1.nombre"),
      titulo: "Meister Ajonjolí",
      color: "#91016C",
    },
    {
      nombre: t("Meister_Plus_1.nombre"),
      titulo: "Meister Plus",
      color: "#91016C",
    },
    {
      nombre: t("8-24-0_1.nombre"),
      titulo: "8-24-0",
      color: "#91016C",
    },
    {
      nombre: t("Humato_De_Calcio_1.nombre"),
      titulo: "Humato De Calcio",
      color: "#91016C",
    },
    {
      nombre: t("Humato_De_Magnesio_1.nombre"),
      titulo: "Humato De Magnesio",
      color: "#91016C",
    },
    {
      nombre: t("Potenz-K_1.nombre"),
      titulo: "Potenz-K",
      color: "#91016C",
    },
    {
      nombre: t("Nitrato_De_Zinc_1.nombre"),
      titulo: "Nitrato De Zinc",
      color: "#91016C",
    },
    {
      nombre: t("Potenz_Brixx_1.nombre"),
      titulo: "Potenz Brixx",
      color: "#91016C",
    },
    {
      nombre: t("Mas_Cana_1.nombre"),
      titulo: "Más Caña",
      color: "#91016C",
    },
    {
      nombre: t("Fost_Alexin-K_1.nombre"),
      titulo: "Fost Alexin-K",
      color: "#91016C",
    },
    {
      nombre: t("Microplus_1.nombre"),
      titulo: "Microplus",
      color: "#91016C",
    },
    {
      nombre: t("Quelatt_Fe_1.nombre"),
      titulo: "Quelatt Fe",
      color: "#91016C",
    },
    {
      nombre: t("10-34-0_1.nombre"),
      titulo: "10-34-0",
      color: "#91016C",
    },
    // BIOESTIMULANTES
    {
      nombre: t("Atp-K_1.nombre"),
      titulo: "Atp-K",
      color: "#F4C241",
    },
    {
      nombre: t("Master_Flower_1.nombre"),
      titulo: "Master Flower",
      color: "#F4C241",
    },
    {
      nombre: t("Potenzamin_50_1.nombre"),
      titulo: "Potenzamin 50",
      color: "#F4C241",
    },
    {
      nombre: t("Root_Drive_1.nombre"),
      titulo: "Root Drive",
      color: "#F4C241",
    },
    {
      nombre: t("Alpromar_30_SL_1.nombre"),
      titulo: "Alpromar 30 SL",
      color: "#F4C241",
    },
    {
      nombre: t("Aminoacidos_80%.nombre"),
      titulo: "Aminoacidos 80",
      color: "#F4C241",
    },
    {
      nombre: t("15-15-15_Master_1.nombre"),
      titulo: "15-15-15 Master",
      color: "#F4C241",
    },
    {
      nombre: t("Microamin_1.nombre"),
      titulo: "Microamin",
      color: "#F4C241",
    },
    {
      nombre: t("Alpromar_100_1.nombre"),
      titulo: "Alpromar 100",
      color: "#F4C241",
    },
    // BIOPROTECTORES
    {
      nombre: t("Fange_20_SL_1.nombre"),
      titulo: "Fange 20 SL",
      color: "#7F7F7F",
    },
    {
      nombre: t("K-Óleo_1.nombre"),
      titulo: "K-oleo",
      color: "#7F7F7F",
    },
    {
      nombre: t("Omicron_Bio_15_SL_1.nombre"),
      titulo: "Omicron Bio 15 SL",
      color: "#7F7F7F",
    },
    {
      nombre: t("Omicron_BF_2000_1.nombre"),
      titulo: "Omicron BF 2000",
      color: "#7F7F7F",
    },
    {
      nombre: t("Potenz_Neen_1.nombre"),
      titulo: "Potenz Neen",
      color: "#7F7F7F",
    },
    {
      nombre: t("Karajo_Ec_1.nombre"),
      titulo: "Karajo Ec",
      color: "#7F7F7F",
    },
    {
      nombre: t("Potenzsil_1.nombre"),
      titulo: "Potenzsil",
      color: "#7F7F7F",
    },
    {
      nombre: t("Lange_Leben_1.nombre"),
      titulo: "Lange Leben",
      color: "#7F7F7F",
    },
    {
      nombre: t("Karanja_85_SL_1.nombre"),
      titulo: "Karanja 85 SL",
    },
    {
      nombre: t("K_OLEO_CANELA.nombre"),
      titulo: "K-Óleo + Canela",
      color: "#7F7F7F",
    },
    //Correctores De Agua Y Suelo
    {
      nombre: t("Abland_SL_1.nombre"),
      titulo: "Abland_SL",
      color: "#05A0EE",
    },
    {
      nombre: t("Abland_SL_75_1.nombre"),
      titulo: "Abland SL 75",
    },
    {
      nombre: t("Abland_N_70_SL_1.nombre"),
      titulo: "Abland N 70 SL",
    },
    {
      nombre: t("Abland_P_80_SL_1.nombre"),
      titulo: "Abland P 80 SL",
    },
    {
      nombre: t("Abland_85_SL.nombre"),
      titulo: "Abland 85 SL",
      color: "#05A0EE",
    },
    {
      nombre: t("Inditur-Acid_45_SL_1.nombre"),
      titulo: "Inditur-Acid 45 SL",
    },
    {
      nombre: t("HCA-25_1.nombre"),
      titulo: "HCA-25",
      color: "#05A0EE",
    },
    {
      nombre: t("Aquamild_40_SL_1.nombre"),
      titulo: "Aquamild 40 SL",
      color: "#05A0EE",
    },
    {
      nombre: t("Descomp_1.nombre"),
      titulo: "Descomp",
      color: "#05A0EE",
    },
    {
      nombre: t("Humi_K80_1.nombre"),
      titulo: "Humi K80",
      color: "#05A0EE",
    },
    // Coadyuvantes
    {
      nombre: t("Spander_100_SL_1.nombre"),
      titulo: "Spander 100 SL",
      color: "#46947D",
    },
    {
      nombre: t("W-30_SL_1.nombre"),
      titulo: "W-30 SL",
      color: "#46947D",
    },
    {
      nombre: t("Film_Kover_83_EC_1.nombre"),
      titulo: "Film Kover 83 EC",
      color: "#46947D",
    },
    {
      nombre: t("Ps_-_Max_1.nombre"),
      titulo: "Ps - Max",
      color: "#46947D",
    },
    {
      nombre: t("Adher_30_Sl_1.nombre"),
      titulo: "Adher 30 SL",
      color: "#46947D",
    },
    {
      nombre: t("Surfaplus_28.7_SL_1.nombre"),
      titulo: "Surfaplus 28.7 SL",
      color: "#46947D",
    },
    {
      nombre: t("Potenzoil_85_SL_1.nombre"),
      titulo: "Potenzoil 85 SL",
      color: "#46947D",
    },
    {
      nombre: t("Kapser_88_SL_1.nombre"),
      titulo: "Kapser 88 SL",
      color: "#46947D",
    },
    {
      nombre: t("Dew_Max_90_SL_1.nombre"),
      titulo: "Dew - Max 90 SL",
      color: "#46947D",
    },
    {
      nombre: t("Emurall_85_SL_1.nombre"),
      titulo: "Emurall 85 SL",
      color: "#46947D",
    },
    // HIDROSOLUBLES
    {
      nombre: t("N_Potenz_1.nombre"),
      titulo: "N-Potenz",
      color: "#15337D",
    },
    {
      nombre: t("Hidrobor_1.nombre"),
      titulo: "Hidrobor",
      color: "#15337D",
    },
    {
      nombre: t("10-40-10+AH+EM_1.nombre"),
      titulo: "10-40-10+AH+EM",
      color: "#15337D",
    },
    {
      nombre: t("20-20-20_1.nombre"),
      titulo: "20-20-20",
      color: "#15337D",
    },
    {
      nombre: t("10-10-40+AH+EM_1.nombre"),
      titulo: "10-10-40+AH+EM",
      color: "#15337D",
    },
    {
      nombre: t("K_Humus_1.nombre"),
      titulo: "K Humus",
      color: "#15337D",
    },
    {
      nombre: t("Potenz_Blend_1.nombre"),
      titulo: "Potenz Blend",
      color: "#15337D",
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredProducts = productos.filter((producto) =>
      producto.nombre.toLowerCase().includes(term)
    );
    setSearchResults(filteredProducts);
  };

  // eslint-disable-next-line no-unused-vars
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <nav
      className="navbar navbar-expand-lg fixed-top"
      style={{
        backgroundColor: `${isSticky ? "white" : "transparent"}`,
        animation: `${
          isSticky
            ? "colorChange 0.3s ease"
            : isSticky === false
            ? "colorChangeBack 0.3s ease"
            : ""
        }`,
      }}
    >
      <div className="container-fluid">
        <a
          className="navbar-brand"
          href="/#"
          style={{ color: `${isSticky ? "black" : "white"}` }}
        >
          {isSticky ? (
            <img
              src={logoPoten}
              alt="Logo"
              width="128"
              height="auto"
              className="d-inline-block align-text-top"
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 5,
              }}
            />
          ) : (
            <img
              src={logoPotenehite}
              alt="Logo"
              width="128"
              height="auto"
              className="d-inline-block align-text-top"
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 5,
              }}
            />
          )}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ backgroundColor: `${isSticky ? "white" : "white"}` }}
        >
          <span
            className="navbar-toggler-icon"
            style={{ color: `${isSticky ? "white" : "white"}` }}
          ></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className="nav-link active regular"
                aria-current="page"
                to={"/"}
                style={{ color: `${isSticky ? "black" : "white"}` }}
              >
                {t("Inicio")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/quiene-somos"}
                className="nav-link active regular"
                aria-current="page"
                style={{ color: `${isSticky ? "black" : "white"}` }}
              >
                {t("Quienes_somos")}
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                to={"/#"}
                className="nav-link dropdown-toggle regular"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ color: `${isSticky ? "black" : "white"}` }}
              >
                {t("Catalogo")}
              </Link>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <Link
                    className="dropdown-item regular"
                    to={"/agrinutrientes"}
                  >
                    <img
                      src={agrinutriente_img}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="especialidades"
                    />{" "}
                    {t("Agrinutrientes")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item regular"
                    to={"/especialidades"}
                  >
                    <img
                      src={especialidades_img}
                      loading="lazy"
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="agrinutrientes"
                    />{" "}
                    {t("Especialidades")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item regular"
                    to={"/bioestimulantes"}
                  >
                    <img
                      src={bioestimulante_img}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="bioestimulante_img"
                    />{" "}
                    {t("Bioestimulantes")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item regular"
                    to={"/bioprotectores"}
                  >
                    <img
                      src={bioprotectores_img}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="bioprotectores_img"
                    />{" "}
                    {t("Bioprotectores")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item regular" to={"/correctoresAS"}>
                    <img
                      src={correctore_img}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="correctore_img"
                    />{" "}
                    {t("Correctores_de_agua_y_suelo")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item regular" to={"/coadyuvantes"}>
                    <img
                      src={coadyuvantes}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="coadyuvantes"
                    />{" "}
                    {t("Coadyuvantes")} &raquo;
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item regular" to={"/hidrosolubles"}>
                    <img
                      src={hidrosolubles}
                      style={{ width: 25, height: 25, borderRadius: 5 }}
                      alt="coadyuvantes"
                    />{" "}
                    {t("Hidrosolubles")} &raquo;
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link active regular"
                aria-current="page"
                to={"/distribuidores"}
                style={{ color: `${isSticky ? "black" : "white"}` }}
              >
                {t("Distribuidores")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/contactanos"}
                className="nav-link active regular"
                aria-current="page"
                style={{ color: `${isSticky ? "black" : "white"}` }}
              >
                {t("Contactanos")}
              </Link>
            </li>
          </ul>

          {/* Dropdown de búsqueda */}
          <div className="dropdown ml-auto">
            <input
              id="searchInput"
              className="form-control"
              type="search"
              placeholder="Buscar por nombre"
              aria-label="Buscar"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={searchTerm}
              onChange={handleSearch}
            />
            {searchTerm !== "" ? (
              <div className="dropdown-menu show" aria-labelledby="searchInput">
                {searchResults.map((product, index) => (
                  <Link
                    to={`/producto/${product.titulo}`}
                    key={index}
                    className="dropdown-item"
                    onClick={() => {
                      console.log("Dimos clik");
                      setSearchResults([]);
                      setSearchTerm("");
                    }}
                  >
                    <strong>{product.nombre}</strong>
                    <br />
                    {product.descripcion}
                  </Link>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
