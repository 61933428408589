import React from "react";
import "./LanguageSelector.css"; // Archivo de estilos CSS para estilizar el select de idioma
import { useLanguage } from "../../context/LanguageContext";

function LanguageSelector() {
  const { changeLanguage } = useLanguage();

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    console.log(selectedLanguage);
    changeLanguage(selectedLanguage);
  };

  return (
    <select className="language-selector" onChange={handleLanguageChange}>
      <option value="es">Español</option>
      <option value="en">English</option>
      <option value="pt" disabled>
        Portugués
      </option>
      <option value="zh" disabled>
        Chino
      </option>

      {/* Agrega más opciones de idioma según sea necesario */}
    </select>
  );
}

export default LanguageSelector;
